import instance from './base';

let professionalAvatarMap;

export async function getProfessionalList() {
  const rsp = await instance.get('/v1/professionals');
  professionalAvatarMap = {};
  rsp.data.forEach((p) => {
    professionalAvatarMap[p.id] = p.avatar?.url || '';
  });
  return rsp.data;
}

export async function newProfessional(
  familyName, givenName, email, avatar, publicStatus, title, branches,
) {
  const rsp = await instance.post('/v1/professionals', {
    family_name: familyName,
    given_name: givenName,
    email,
    avatar,
    public: publicStatus,
    title,
    branches,
  });
  return rsp.data;
}

export async function updateProfessional(
  id, familyName, givenName, email, avatar, publicStatus, title, branches,
) {
  const rsp = await instance.put(`/v1/professionals/${id}`, {
    family_name: familyName,
    given_name: givenName,
    email,
    avatar,
    public: publicStatus,
    title,
    branches,
  });
  return rsp.data;
}

export async function updateProfessionalPublic(id, publicStatus) {
  const rsp = await instance.patch(`/v1/professionals/${id}/public`, {
    public: publicStatus,
  });
  return rsp.data;
}

export async function updateProfessionalStatus(id, activation) {
  const rsp = await instance.patch(`/v1/professionals/${id}/activation`, {
    activated: activation,
  });
  return rsp.data;
}

export async function createAvatar(file) {
  const formData = new FormData();
  formData.append('avatar', file);
  const rsp = await instance.post('/v1/professionals/avatar', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return rsp.data;
}

export async function getProfessionalAvatarURL(id) {
  if (professionalAvatarMap === undefined) {
    await getProfessionalList();
  }
  return professionalAvatarMap[id];
}
