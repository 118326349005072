import instance from './base';

export async function addAppointment(appointment) {
  const rsp = await instance.post('/v1/appointments', appointment);
  return rsp.data;
}

export async function updateAppointment(id, data) {
  const rsp = await instance.put(`/v1/appointments/${id}`, data);
  return rsp.data;
}

export async function getAppointments(params) {
  const rsp = await instance.get('/v1/appointments', { params });
  return rsp.data;
}

export async function getAllAppointments(params) {
  const result = [];
  let finish = false;
  let page = 0;
  const limit = 100;

  while (!finish) {
    const reqParam = {
      ...params,
      page,
      limit,
    };
    // eslint-disable-next-line no-await-in-loop
    const rsp = await instance.get('/v1/appointments', { params: reqParam });
    if (rsp.data?.meta === undefined) {
      // old format
      return rsp.data;
    }

    result.push(...(rsp.data?.appointments || []));
    if ((page + 1) * limit >= (rsp.data?.meta.total || 0)) {
      finish = true;
    } else {
      page += 1;
    }
  }
  return result;
}

export async function updateAppointmentTags(id, tags) {
  const rsp = await instance.put(`/v1/appointments/${id}/tags`, {
    tags,
  });
  return rsp.data;
}

export async function generateRemoteLink(professionalID, userID) {
  const rsp = await instance.post('/v1/appointments/meetinglink', {
    professional: professionalID,
    patient: userID,
  });
  return rsp.data;
}

export async function updatePayment(id, method) {
  const rsp = await instance.patch(`/v1/appointments/${id}/payment`, {
    payment_method: method,
  });
  return rsp.data;
}

export async function getAppointment(id) {
  const rsp = await instance.get(`/v1/appointments/${id}`);
  return rsp.data;
}

export async function getUserLastAppointment(userID) {
  const rsp = await instance.get(`/v1/users/${userID}/last_appointment`);
  return getAppointment(rsp.data.id);
}

export async function cancelAppointment(id) {
  const rsp = await instance.delete(`/v1/appointments/${id}`);
  return rsp.data;
}

export async function updateAppointmentRecord(aid, data) {
  const rsp = await instance.put(`/v1/appointments/${aid}/record`, data);
  return rsp.data;
}

export async function getAppointmentRecord(aid) {
  const rsp = await instance.get(`/v1/appointments/${aid}/record`);
  return rsp.data;
}

export async function getRecordTags(aid) {
  const rsp = await instance.get(`/v1/appointments/${aid}/record_tags`);
  return rsp.data;
}

export async function createAppointmentTx(aid) {
  const rsp = await instance.post(`/v1/appointments/${aid}/transaction`);
  return rsp.data;
}

export async function getAppointmentRecordTemplate(aid) {
  const rsp = await instance.get(`/v1/appointments/${aid}/templates`);
  return rsp.data;
}

export async function addAppointmentRecordFile(aid, file) {
  const formData = new FormData();
  formData.append('attachment', file);
  const rsp = await instance.post(`/v1/appointments/${aid}/attachments`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return rsp.data;
}

export async function updateAppointmentPlace(aid, location) {
  const rsp = await instance.post(`/v1/appointments/${aid}/location_declaration`, {
    location,
  });
  return rsp.data;
}

export async function deleteAppointmentAttachment(aid, attachId) {
  const rsp = await instance.delete(`/v1/appointments/${aid}/attachments/${attachId}`);
  return rsp.data;
}

export async function setAppointmentNoted(aid, value) {
  const rsp = await instance.put(`/v1/appointments/${aid}/noted`, { value });
  return rsp.data;
}

export async function updateAppointmentNote(aid, note) {
  const rsp = await instance.put(`/v1/appointments/${aid}/note`, { note });
  return rsp.data;
}
